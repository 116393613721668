









































































import { AddScholarConfirmation } from "@/popupcomponents/addscholarconfirmation/AddScholarConfirmation";
export default AddScholarConfirmation;
