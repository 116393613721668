import { Vue, Component, Prop } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import LocalStorage from "@/utilities/localStorageUtil";
import APP_UTILITIES from "@/utilities/commonFunctions";
@Component
export class AddScholarConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public openCreatePanel:boolean = false;
    public  panelTitle:string = '';

    @Prop()
    scholarName! : string;
    
    @Prop()
    isMobile!: boolean;

  
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    closePopup() {
        this.$emit(APP_CONST.CLOSE);
        document.body.style.pointerEvents ="all"
    }
     checkBoxHandler(e:any) {
        if(e.target.checked){
            LocalStorage.saveInLocalStorage("isScholerPoppopShow", APP_UTILITIES.getCookie('auth_token'));
            document.body.style.pointerEvents = "all";
        }else{
            LocalStorage.deleteFromLocalStorage("isScholerPoppopShow")
        }
       }

    openAddPanel() {
        this.$emit('openRoasterPanel');
    }

}